import React, { createContext, useState, useContext, useEffect } from "react"
import PubSub from "pubsub-js"

const ShopContext = createContext()
// const initialState = {
//   ready: false,
//   userStatus: "SignedOut",
//   cartQuantity: 0,
//   cartTotal: 0,
//   cartSubTotal: 0,
//   products: [],
// }

export const ShopWrapper = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [userStatus, setUserStatus] = useState("")
  const [customer, setCustomer] = useState()
  const [cart, setCart] = useState()
  const [products] = useState([])
  // const [Shop, dispatchShop] = useState(initialState);

  useEffect(() => {
    // console.log(window)

    // window.SnipcartSettings = {
    //   publicApiKey: process.env.GATSBY_SNIPCART_API_KEY,
    //   loadStrategy: "on-user-interaction",
    //   modalStyle: "side",
    // }

    const { Snipcart } = window
    if (!Snipcart) {
      document.addEventListener("snipcart.ready", e => {
        // console.log(e)
        setReady(true)
      })
    } else {
      setReady(true)
    }
  }, [])

  useEffect(() => {
    // return
    if (!ready) return
    const { Snipcart } = window
    // console.log(window)
    // console.log(Snipcart)
    // console.log(window.SnipcartSettings)
    console.log(Snipcart.api)
    // Snipcart.api.configure("split_firstname_and_lastname", true)
    Snipcart.api.session.setLanguage("fr", {
      // actions: {
      //   continue_shopping: "Go back to store",
      // },
      header: {
        title_cart_summary: "Panier",
        // loading: "Chargement…",
      },
      cart: {
        view_detailed_cart: "Voir le détail du panier",
      },
    })
    Snipcart.api.session.setCurrency("eur")

    const listenSnipcart = () => {
      const { customer, cart } = Snipcart.store.getState()
      // console.log(Snipcart.store.getState().cart.items.items)
      setCustomer(customer)
      setUserStatus(customer.status)
      setCart(cart)

      Snipcart.events.on("item.adding", (item, items) => {
        // console.log("item.adding", item)
        PubSub.publish("item.adding", item)
      })
      Snipcart.events.on("item.added", cartItem => {
        // console.log("item.added", cartItem)
        PubSub.publish("item.added", cartItem)
      })

      Snipcart.events.on("cart.confirm.error", confirmError => {
        console.log(confirmError)
      })
    }
    // listen store update
    const unsubscribe = Snipcart.store.subscribe(listenSnipcart)
    // call first
    listenSnipcart()
    return () => unsubscribe()
  }, [ready])

  return (
    <ShopContext.Provider
      value={{ ready, userStatus, customer, cart, products }}
    >
      {children}
    </ShopContext.Provider>
  )
}
export default function useShop() {
  return useContext(ShopContext)
}
